const ChapterPages = [
    {
        "id": 1,
        "title": "Prologue",
        "page": 2,
        "pageTab": 1,
        "pageMobile": 1,
        "subpage": false,
        "pageIndex": 4,
        "pageIndexTab": 5,
        "pageIndexMobile": 8
    },
    {
        "id": 2,
        "title": "Chapter 1: The Inception",
        "page": 5,
        "pageTab": 4,
        "pageMobile": 4,
        "subpage": false,
        "pageIndex": 6,
        "pageIndexTab": 8,
        "pageIndexMobile": 11
    },
    {
        "id": 3,
        "title": "The Tale of AbracaDoobie",
        "page": 11,
        "pageTab": 8,
        "pageMobile": 9,
        "subpage": true,
        "pageIndex": 9,
        "pageIndexTab": 12,
        "pageIndexMobile": 16
    },
    {
        "id": 4,
        "title": "The Tale of Aster",
        "page": 13,
        "pageTab": 9,
        "pageMobile": 10,
        "subpage": true,
        "pageIndex": 10,
        "pageIndexTab": 13,
        "pageIndexMobile": 17
    },
    {
        "id": 5,
        "title": "The Tale of Bacon Prince",
        "page": 15,
        "pageTab": 10,
        "pageMobile": 11,
        "subpage": true,
        "pageIndex": 11,
        "pageIndexTab": 14,
        "pageIndexMobile": 18
    },
    {
        "id": 6,
        "title": "The Tale of Bandwip",
        "page": 17,
        "pageTab": 11,
        "pageMobile": 12,
        "subpage": true,
        "pageIndex": 12,
        "pageIndexTab": 15,
        "pageIndexMobile": 19
    },
    {
        "id": 7,
        "title": "The Tale of Bee Lee",
        "page": 19,
        "pageTab": 12,
        "pageMobile": 13,
        "subpage": true,
        "pageIndex": 13,
        "pageIndexTab": 16,
        "pageIndexMobile": 20
    },
    {
        "id": 8,
        "title": "The Tale of Berry",
        "page": 21,
        "pageTab": 13,
        "pageMobile": 14,
        "subpage": true,
        "pageIndex": 14,
        "pageIndexTab": 17,
        "pageIndexMobile": 21
    },
    {
        "id": 9,
        "title": "The Tale of Bug",
        "page": 23,
        "pageTab": 14,
        "pageMobile": 15,
        "subpage": true,
        "pageIndex": 15,
        "pageIndexTab": 18,
        "pageIndexMobile": 22
    },
    {
        "id": 10,
        "title": "The Tale of Cairo",
        "page": 25,
        "pageTab": 15,
        "pageMobile": 16,
        "subpage": true,
        "pageIndex": 16,
        "pageIndexTab": 19,
        "pageIndexMobile": 23
    },
    {
        "id": 11,
        "title": "The Tale of Gamer Boi",
        "page": 27,
        "pageTab": 16,
        "pageMobile": 17,
        "subpage": true,
        "pageIndex": 17,
        "pageIndexTab": 20,
        "pageIndexMobile": 24
    },
    {
        "id": 12,
        "title": "The Tale of Pulsar",
        "page": 29,
        "pageTab": 17,
        "pageMobile": 18,
        "subpage": true,
        "pageIndex": 18,
        "pageIndexTab": 21,
        "pageIndexMobile": 25
    },
    {
        "id": 13,
        "title": "Chapter 2: The Blade at Fellowcro",
        "page": 32,
        "pageTab": 18,
        "pageMobile": 19,
        "subpage": false,
        "pageIndex": 19,
        "pageIndexTab": 22,
        "pageIndexMobile": 26
    },
    {
        "id": 14,
        "title": "The Tale of Candy Cane",
        "page": 39,
        "pageTab": 23,
        "pageMobile": 25,
        "subpage": true,
        "pageIndex": 23,
        "pageIndexTab": 27,
        "pageIndexMobile": 32
    },
    {
        "id": 15,
        "title": "The Tale of Candy Witch",
        "page": 41,
        "pageTab": 24,
        "pageMobile": 26,
        "subpage": true,
        "pageIndex": 24,
        "pageIndexTab": 28,
        "pageIndexMobile": 33
    },
    {
        "id": 16,
        "title": "The Tale of CarabaoHead",
        "page": 43,
        "pageTab": 25,
        "pageMobile": 27,
        "subpage": true,
        "pageIndex": 25,
        "pageIndexTab": 29,
        "pageIndexMobile": 34
    },
    {
        "id": 17,
        "title": "The Tale of Carbon",
        "page": 45,
        "pageTab": 26,
        "pageMobile": 28,
        "subpage": true,
        "pageIndex": 26,
        "pageIndexTab": 30,
        "pageIndexMobile": 35
    },
    {
        "id": 18,
        "title": "The Tale of Caster",
        "page": 47,
        "pageTab": 27,
        "pageMobile": 29,
        "subpage": true,
        "pageIndex": 27,
        "pageIndexTab": 31,
        "pageIndexMobile": 36
    },
    {
        "id": 19,
        "title": "The Tale of Catch A Wave",
        "page": 49,
        "pageTab": 28,
        "pageMobile": 30,
        "subpage": true,
        "pageIndex": 28,
        "pageIndexTab": 32,
        "pageIndexMobile": 37
    },
    {
        "id": 20,
        "title": "The Tale of Cave Wizard",
        "page": 51,
        "pageTab": 29,
        "pageMobile": 31,
        "subpage": true,
        "pageIndex": 29,
        "pageIndexTab": 33,
        "pageIndexMobile": 38
    },
    {
        "id": 21,
        "title": "The Tale of Climber",
        "page": 53,
        "pageTab": 30,
        "pageMobile": 32,
        "subpage": true,
        "pageIndex": 30,
        "pageIndexTab": 34,
        "pageIndexMobile": 39
    },
    {
        "id": 22,
        "title": "The Tale of Rench",
        "page": 55,
        "pageTab": 31,
        "pageMobile": 33,
        "subpage": true,
        "pageIndex": 31,
        "pageIndexTab": 35,
        "pageIndexMobile": 40
    },
    {
        "id": 23,
        "title": "The Tale of Shucks",
        "page": 57,
        "pageTab": 32,
        "pageMobile": 34,
        "subpage": true,
        "pageIndex": 32,
        "pageIndexTab": 36,
        "pageIndexMobile": 41
    },
    {
        "id": 24,
        "title": "Chapter 3: Red, White, and Blue",
        "page": 60,
        "pageTab": 33,
        "pageMobile": 35,
        "subpage": false,
        "pageIndex": 33,
        "pageIndexTab": 37,
        "pageIndexMobile": 42
    },
    {
        "id": 25,
        "title": "The Tale of Candy Floss",
        "page": 67,
        "pageTab": 38,
        "pageMobile": 41,
        "subpage": true,
        "pageIndex": 37,
        "pageIndexTab": 42,
        "pageIndexMobile": 48
    },
    {
        "id": 26,
        "title": "The Tale of Door Keeper",
        "page": 69,
        "pageTab": 39,
        "pageMobile": 42,
        "subpage": true,
        "pageIndex": 38,
        "pageIndexTab": 43,
        "pageIndexMobile": 49
    },
    {
        "id": 27,
        "title": "The Tale of Dwinn",
        "page": 71,
        "pageTab": 40,
        "pageMobile": 43,
        "subpage": true,
        "pageIndex": 39,
        "pageIndexTab": 44,
        "pageIndexMobile": 50
    },
    {
        "id": 28,
        "title": "The Tale of Eddie Crog",
        "page": 73,
        "pageTab": 41,
        "pageMobile": 44,
        "subpage": true,
        "pageIndex": 40,
        "pageIndexTab": 45,
        "pageIndexMobile": 51
    },
    {
        "id": 29,
        "title": "The Tale of EVL Guy",
        "page": 75,
        "pageTab": 42,
        "pageMobile": 45,
        "subpage": true,
        "pageIndex": 41,
        "pageIndexTab": 46,
        "pageIndexMobile": 52
    },
    {
        "id": 30,
        "title": "The Tale of Farmer",
        "page": 77,
        "pageTab": 43,
        "pageMobile": 46,
        "subpage": true,
        "pageIndex": 42,
        "pageIndexTab": 47,
        "pageIndexMobile": 53
    },
    {
        "id": 31,
        "title": "The Tale of Faun",
        "page": 79,
        "pageTab": 44,
        "pageMobile": 47,
        "subpage": true,
        "pageIndex": 43,
        "pageIndexTab": 48,
        "pageIndexMobile": 54
    },
    {
        "id": 32,
        "title": "The Tale of Frankie Stan",
        "page": 81,
        "pageTab": 45,
        "pageMobile": 48,
        "subpage": true,
        "pageIndex": 44,
        "pageIndexTab": 49,
        "pageIndexMobile": 55
    },
    {
        "id": 33,
        "title": "The Tale of Frontline",
        "page": 83,
        "pageTab": 46,
        "pageMobile": 49,
        "subpage": true,
        "pageIndex": 45,
        "pageIndexTab": 50,
        "pageIndexMobile": 56
    },
    {
        "id": 34,
        "title": "The Tale of Verdant Greed",
        "page": 85,
        "pageTab": 47,
        "pageMobile": 50,
        "subpage": true,
        "pageIndex": 46,
        "pageIndexTab": 51,
        "pageIndexMobile": 57
    },
    {
        "id": 35,
        "title": "Chapter 4: The Secret of the Southern Seas",
        "page": 88,
        "pageTab": 48,
        "pageMobile": 51,
        "subpage": false,
        "pageIndex": 47,
        "pageIndexTab": 52,
        "pageIndexMobile": 58
    },
    {
        "id": 36,
        "title": "The Tale of Dark Knight",
        "page": 95,
        "pageTab": 53,
        "pageMobile": 58,
        "subpage": true,
        "pageIndex": 51,
        "pageIndexTab": 57,
        "pageIndexMobile": 65
    },
    {
        "id": 37,
        "title": "The Tale of Fire Cracker Jack",
        "page": 97,
        "pageTab": 54,
        "pageMobile": 59,
        "subpage": true,
        "pageIndex": 52,
        "pageIndexTab": 58,
        "pageIndexMobile": 66
    },
    {
        "id": 38,
        "title": "The Tale of Frosty",
        "page": 99,
        "pageTab": 55,
        "pageMobile": 60,
        "subpage": true,
        "pageIndex": 53,
        "pageIndexTab": 59,
        "pageIndexMobile": 67
    },
    {
        "id": 39,
        "title": "The Tale of Ghost Clarence",
        "page": 101,
        "pageTab": 56,
        "pageMobile": 61,
        "subpage": true,
        "pageIndex": 54,
        "pageIndexTab": 60,
        "pageIndexMobile": 68
    },
    {
        "id": 40,
        "title": "The Tale of Ghost Face",
        "page": 103,
        "pageTab": 57,
        "pageMobile": 62,
        "subpage": true,
        "pageIndex": 55,
        "pageIndexTab": 61,
        "pageIndexMobile": 69
    },
    {
        "id": 41,
        "title": "The Tale of Ghost Prince",
        "page": 105,
        "pageTab": 58,
        "pageMobile": 63,
        "subpage": true,
        "pageIndex": 56,
        "pageIndexTab": 62,
        "pageIndexMobile": 70
    },
    {
        "id": 42,
        "title": "The Tale of Ghost",
        "page": 107,
        "pageTab": 59,
        "pageMobile": 64,
        "subpage": true,
        "pageIndex": 57,
        "pageIndexTab": 63,
        "pageIndexMobile": 71
    },
    {
        "id": 43,
        "title": "The Tale of Grim",
        "page": 109,
        "pageTab": 60,
        "pageMobile": 65,
        "subpage": true,
        "pageIndex": 58,
        "pageIndexTab": 64,
        "pageIndexMobile": 72
    },
    {
        "id": 44,
        "title": "The Tale of King Hobgoblin",
        "page": 111,
        "pageTab": 61,
        "pageMobile": 66,
        "subpage": true,
        "pageIndex": 59,
        "pageIndexTab": 65,
        "pageIndexMobile": 73
    },
    {
        "id": 45,
        "title": "The Tale of Turfy Wizard",
        "page": 113,
        "pageTab": 62,
        "pageMobile": 67,
        "subpage": true,
        "pageIndex": 60,
        "pageIndexTab": 66,
        "pageIndexMobile": 74
    },
    {
        "id": 46,
        "title": "Chapter 5: Myst of the Ancient Cellar",
        "page": 116,
        "pageTab": 63,
        "pageMobile": 68,
        "subpage": false,
        "pageIndex": 61,
        "pageIndexTab": 67,
        "pageIndexMobile": 75
    },
    {
        "id": 47,
        "title": "The Tale of Creepy Strawhat",
        "page": 123,
        "pageTab": 67,
        "pageMobile": 75,
        "subpage": true,
        "pageIndex": 65,
        "pageIndexTab": 71,
        "pageIndexMobile": 82
    },
    {
        "id": 48,
        "title": "The Tale of Crimson",
        "page": 125,
        "pageTab": 68,
        "pageMobile": 76,
        "subpage": true,
        "pageIndex": 66,
        "pageIndexTab": 72,
        "pageIndexMobile": 83
    },
    {
        "id": 49,
        "title": "The Tale of Dummy",
        "page": 127,
        "pageTab": 69,
        "pageMobile": 77,
        "subpage": true,
        "pageIndex": 67,
        "pageIndexTab": 73,
        "pageIndexMobile": 84
    },
    {
        "id": 50,
        "title": "The Tale of Hot Dog Prince",
        "page": 129,
        "pageTab": 70,
        "pageMobile": 78,
        "subpage": true,
        "pageIndex": 68,
        "pageIndexTab": 74,
        "pageIndexMobile": 85
    },
    {
        "id": 51,
        "title": "The Tale of Incubus",
        "page": 131,
        "pageTab": 71,
        "pageMobile": 79,
        "subpage": true,
        "pageIndex": 69,
        "pageIndexTab": 75,
        "pageIndexMobile": 86
    },
    {
        "id": 52,
        "title": "The Tale of Inka",
        "page": 133,
        "pageTab": 72,
        "pageMobile": 80,
        "subpage": true,
        "pageIndex": 70,
        "pageIndexTab": 76,
        "pageIndexMobile": 87
    },
    {
        "id": 53,
        "title": "The Tale of Jason D Killer",
        "page": 135,
        "pageTab": 73,
        "pageMobile": 81,
        "subpage": true,
        "pageIndex": 71,
        "pageIndexTab": 77,
        "pageIndexMobile": 88
    },
    {
        "id": 54,
        "title": "The Tale of Jungle",
        "page": 137,
        "pageTab": 74,
        "pageMobile": 82,
        "subpage": true,
        "pageIndex": 72,
        "pageIndexTab": 78,
        "pageIndexMobile": 89
    },
    {
        "id": 55,
        "title": "The Tale of King Stellar",
        "page": 139,
        "pageTab": 75,
        "pageMobile": 83,
        "subpage": true,
        "pageIndex": 73,
        "pageIndexTab": 79,
        "pageIndexMobile": 90
    },
    {
        "id": 56,
        "title": "The Tale of Leather Face",
        "page": 141,
        "pageTab": 76,
        "pageMobile": 84,
        "subpage": true,
        "pageIndex": 74,
        "pageIndexTab": 80,
        "pageIndexMobile": 91
    },
    {
        "id": 57,
        "title": "Chapter 6: The Chaos by the Tavern",
        "page": 144,
        "pageTab": 77,
        "pageMobile": 85,
        "subpage": false,
        "pageIndex": 75,
        "pageIndexTab": 81,
        "pageIndexMobile": 92
    },
    {
        "id": 58,
        "title": "The Tale of Forest Lord",
        "page": 151,
        "pageTab": 82,
        "pageMobile": 92,
        "subpage": true,
        "pageIndex": 79,
        "pageIndexTab": 86,
        "pageIndexMobile": 99
    },
    {
        "id": 59,
        "title": "The Tale of Forest Warrior",
        "page": 153,
        "pageTab": 83,
        "pageMobile": 93,
        "subpage": true,
        "pageIndex": 80,
        "pageIndexTab": 87,
        "pageIndexMobile": 100
    },
    {
        "id": 60,
        "title": "The Tale of Frozen Sundae",
        "page": 155,
        "pageTab": 84,
        "pageMobile": 94,
        "subpage": true,
        "pageIndex": 81,
        "pageIndexTab": 88,
        "pageIndexMobile": 101
    },
    {
        "id": 61,
        "title": "The Tale of Leprechaun",
        "page": 157,
        "pageTab": 85,
        "pageMobile": 95,
        "subpage": true,
        "pageIndex": 82,
        "pageIndexTab": 89,
        "pageIndexMobile": 102
    },
    {
        "id": 62,
        "title": "The Tale of LichKing",
        "page": 159,
        "pageTab": 86,
        "pageMobile": 96,
        "subpage": true,
        "pageIndex": 83,
        "pageIndexTab": 90,
        "pageIndexMobile": 103
    },
    {
        "id": 63,
        "title": "The Tale of Lisby",
        "page": 161,
        "pageTab": 87,
        "pageMobile": 97,
        "subpage": true,
        "pageIndex": 84,
        "pageIndexTab": 91,
        "pageIndexMobile": 104
    },
    {
        "id": 64,
        "title": "The Tale of Lizzard Prince",
        "page": 163,
        "pageTab": 88,
        "pageMobile": 98,
        "subpage": true,
        "pageIndex": 85,
        "pageIndexTab": 92,
        "pageIndexMobile": 105
    },
    {
        "id": 65,
        "title": "The Tale of LSP",
        "page": 165,
        "pageTab": 89,
        "pageMobile": 99,
        "subpage": true,
        "pageIndex": 86,
        "pageIndexTab": 93,
        "pageIndexMobile": 106
    },
    {
        "id": 66,
        "title": "The Tale of Lullaby",
        "page": 167,
        "pageTab": 90,
        "pageMobile": 100,
        "subpage": true,
        "pageIndex": 87,
        "pageIndexTab": 94,
        "pageIndexMobile": 107
    },
    {
        "id": 67,
        "title": "The Tale of Squarehead",
        "page": 169,
        "pageTab": 91,
        "pageMobile": 101,
        "subpage": true,
        "pageIndex": 88,
        "pageIndexTab": 95,
        "pageIndexMobile": 108
    },
    {
        "id": 68,
        "title": "Chapter 7: The Conspiracy at Aeolian",
        "page": 172,
        "pageTab": 92,
        "pageMobile": 102,
        "subpage": false,
        "pageIndex": 89,
        "pageIndexTab": 96,
        "pageIndexMobile": 109
    },
    {
        "id": 69,
        "title": "The Tale of Magic Man",
        "page": 181,
        "pageTab": 97,
        "pageMobile": 109,
        "subpage": true,
        "pageIndex": 94,
        "pageIndexTab": 101,
        "pageIndexMobile": 116
    },
    {
        "id": 70,
        "title": "The Tale of Marci",
        "page": 183,
        "pageTab": 98,
        "pageMobile": 110,
        "subpage": true,
        "pageIndex": 95,
        "pageIndexTab": 102,
        "pageIndexMobile": 117
    },
    {
        "id": 71,
        "title": "The Tale of Merman Warrior",
        "page": 185,
        "pageTab": 99,
        "pageMobile": 111,
        "subpage": true,
        "pageIndex": 96,
        "pageIndexTab": 103,
        "pageIndexMobile": 118
    },
    {
        "id": 72,
        "title": "The Tale of Mexico",
        "page": 187,
        "pageTab": 100,
        "pageMobile": 112,
        "subpage": true,
        "pageIndex": 97,
        "pageIndexTab": 104,
        "pageIndexMobile": 119
    },
    {
        "id": 73,
        "title": "The Tale of Mountain Warrior",
        "page": 189,
        "pageTab": 101,
        "pageMobile": 113,
        "subpage": true,
        "pageIndex": 98,
        "pageIndexTab": 105,
        "pageIndexMobile": 120
    },
    {
        "id": 74,
        "title": "The Tale of Mountain Warrior 2",
        "page": 191,
        "pageTab": 102,
        "pageMobile": 114,
        "subpage": true,
        "pageIndex": 99,
        "pageIndexTab": 106,
        "pageIndexMobile": 121
    },
    {
        "id": 75,
        "title": "The Tale of Neos",
        "page": 193,
        "pageTab": 103,
        "pageMobile": 115,
        "subpage": true,
        "pageIndex": 100,
        "pageIndexTab": 107,
        "pageIndexMobile": 122
    },
    {
        "id": 76,
        "title": "The Tale of Nightmare",
        "page": 195,
        "pageTab": 104,
        "pageMobile": 116,
        "subpage": true,
        "pageIndex": 101,
        "pageIndexTab": 108,
        "pageIndexMobile": 123
    },
    {
        "id": 77,
        "title": "The Tale of Ninja",
        "page": 197,
        "pageTab": 105,
        "pageMobile": 117,
        "subpage": true,
        "pageIndex": 102,
        "pageIndexTab": 109,
        "pageIndexMobile": 124
    },
    {
        "id": 78,
        "title": "The Tale of Onku",
        "page": 199,
        "pageTab": 106,
        "pageMobile": 118,
        "subpage": true,
        "pageIndex": 103,
        "pageIndexTab": 110,
        "pageIndexMobile": 125
    },
    {
        "id": 79,
        "title": "Chapter 8: What Confides in The Land Of Colossus",
        "page": 202,
        "pageTab": 107,
        "pageMobile": 119,
        "subpage": false,
        "pageIndex": 104,
        "pageIndexTab": 111,
        "pageIndexMobile": 126
    },
    {
        "id": 80,
        "title": "The Tale of BubbleGum",
        "page": 209,
        "pageTab": 111,
        "pageMobile": 126,
        "subpage": true,
        "pageIndex": 108,
        "pageIndexTab": 115,
        "pageIndexMobile": 133
    },
    {
        "id": 81,
        "title": "The Tale of Loonywise",
        "page": 211,
        "pageTab": 112,
        "pageMobile": 127,
        "subpage": true,
        "pageIndex": 109,
        "pageIndexTab": 116,
        "pageIndexMobile": 134
    },
    {
        "id": 82,
        "title": "The Tale of Paddle",
        "page": 213,
        "pageTab": 113,
        "pageMobile": 128,
        "subpage": true,
        "pageIndex": 110,
        "pageIndexTab": 117,
        "pageIndexMobile": 135
    },
    {
        "id": 83,
        "title": "The Tale of PanCake Prince",
        "page": 215,
        "pageTab": 114,
        "pageMobile": 129,
        "subpage": true,
        "pageIndex": 111,
        "pageIndexTab": 118,
        "pageIndexMobile": 136
    },
    {
        "id": 84,
        "title": "The Tale of Paratrooper",
        "page": 217,
        "pageTab": 115,
        "pageMobile": 130,
        "subpage": true,
        "pageIndex": 112,
        "pageIndexTab": 119,
        "pageIndexMobile": 137
    },
    {
        "id": 85,
        "title": "The Tale of Peco Rider",
        "page": 219,
        "pageTab": 116,
        "pageMobile": 131,
        "subpage": true,
        "pageIndex": 113,
        "pageIndexTab": 120,
        "pageIndexMobile": 138
    },
    {
        "id": 86,
        "title": "The Tale of Penny",
        "page": 221,
        "pageTab": 117,
        "pageMobile": 132,
        "subpage": true,
        "pageIndex": 114,
        "pageIndexTab": 121,
        "pageIndexMobile": 139
    },
    {
        "id": 87,
        "title": "The Tale of PepperMint Butler",
        "page": 223,
        "pageTab": 118,
        "pageMobile": 133,
        "subpage": true,
        "pageIndex": 115,
        "pageIndexTab": 122,
        "pageIndexMobile": 140
    },
    {
        "id": 88,
        "title": "The Tale of Pilot",
        "page": 225,
        "pageTab": 119,
        "pageMobile": 134,
        "subpage": true,
        "pageIndex": 116,
        "pageIndexTab": 123,
        "pageIndexMobile": 141
    },
    {
        "id": 89,
        "title": "The Tale of Pumpkin Man",
        "page": 227,
        "pageTab": 120,
        "pageMobile": 135,
        "subpage": true,
        "pageIndex": 117,
        "pageIndexTab": 124,
        "pageIndexMobile": 142
    },
    {
        "id": 90,
        "title": "Chapter 9: The Sky's Contempt at Fellowcro",
        "page": 230,
        "pageTab": 121,
        "pageMobile": 136,
        "subpage": false,
        "pageIndex": 118,
        "pageIndexTab": 125,
        "pageIndexMobile": 143
    },
    {
        "id": 91,
        "title": "The Tale of Dako",
        "page": 237,
        "pageTab": 125,
        "pageMobile": 143,
        "subpage": true,
        "pageIndex": 122,
        "pageIndexTab": 129,
        "pageIndexMobile": 150
    },
    {
        "id": 92,
        "title": "The Tale of Laya",
        "page": 239,
        "pageTab": 126,
        "pageMobile": 144,
        "subpage": true,
        "pageIndex": 123,
        "pageIndexTab": 130,
        "pageIndexMobile": 151
    },
    {
        "id": 93,
        "title": "The Tale of Raggedy Prince",
        "page": 241,
        "pageTab": 127,
        "pageMobile": 145,
        "subpage": true,
        "pageIndex": 124,
        "pageIndexTab": 131,
        "pageIndexMobile": 152
    },
    {
        "id": 94,
        "title": "The Tale of Scare Crow",
        "page": 243,
        "pageTab": 128,
        "pageMobile": 146,
        "subpage": true,
        "pageIndex": 125,
        "pageIndexTab": 132,
        "pageIndexMobile": 153
    },
    {
        "id": 95,
        "title": "The Tale of Shoko",
        "page": 245,
        "pageTab": 129,
        "pageMobile": 147,
        "subpage": true,
        "pageIndex": 126,
        "pageIndexTab": 133,
        "pageIndexMobile": 154
    },
    {
        "id": 96,
        "title": "The Tale of Sign Maker",
        "page": 247,
        "pageTab": 130,
        "pageMobile": 148,
        "subpage": true,
        "pageIndex": 127,
        "pageIndexTab": 134,
        "pageIndexMobile": 155
    },
    {
        "id": 97,
        "title": "The Tale of Simone",
        "page": 249,
        "pageTab": 131,
        "pageMobile": 149,
        "subpage": true,
        "pageIndex": 128,
        "pageIndexTab": 135,
        "pageIndexMobile": 156
    },
    {
        "id": 98,
        "title": "The Tale of SkateZone",
        "page": 251,
        "pageTab": 132,
        "pageMobile": 150,
        "subpage": true,
        "pageIndex": 129,
        "pageIndexTab": 136,
        "pageIndexMobile": 157
    },
    {
        "id": 99,
        "title": "The Tale of Skeleton Prince",
        "page": 253,
        "pageTab": 133,
        "pageMobile": 151,
        "subpage": true,
        "pageIndex": 130,
        "pageIndexTab": 137,
        "pageIndexMobile": 158
    },
    {
        "id": 100,
        "title": "The Tale of Slendie",
        "page": 255,
        "pageTab": 134,
        "pageMobile": 152,
        "subpage": true,
        "pageIndex": 131,
        "pageIndexTab": 138,
        "pageIndexMobile": 159
    },
    {
        "id": 101,
        "title": "Chapter 10: Trapped in the Void of Time",
        "page": 258,
        "pageTab": 135,
        "pageMobile": 153,
        "subpage": false,
        "pageIndex": 132,
        "pageIndexTab": 139,
        "pageIndexMobile": 160
    },
    {
        "id": 102,
        "title": "The Tale of Sludgie",
        "page": 265,
        "pageTab": 139,
        "pageMobile": 160,
        "subpage": true,
        "pageIndex": 136,
        "pageIndexTab": 143,
        "pageIndexMobile": 167
    },
    {
        "id": 103,
        "title": "The Tale of Space Man",
        "page": 267,
        "pageTab": 140,
        "pageMobile": 161,
        "subpage": true,
        "pageIndex": 137,
        "pageIndexTab": 144,
        "pageIndexMobile": 168
    },
    {
        "id": 104,
        "title": "The Tale of Space Pirate",
        "page": 269,
        "pageTab": 141,
        "pageMobile": 162,
        "subpage": true,
        "pageIndex": 138,
        "pageIndexTab": 145,
        "pageIndexMobile": 169
    },
    {
        "id": 105,
        "title": "The Tale of Spirit Dream",
        "page": 271,
        "pageTab": 142,
        "pageMobile": 163,
        "subpage": true,
        "pageIndex": 139,
        "pageIndexTab": 146,
        "pageIndexMobile": 170
    },
    {
        "id": 106,
        "title": "The Tale of Stan March",
        "page": 273,
        "pageTab": 143,
        "pageMobile": 164,
        "subpage": true,
        "pageIndex": 140,
        "pageIndexTab": 147,
        "pageIndexMobile": 171
    },
    {
        "id": 107,
        "title": "The Tale of StopOver",
        "page": 275,
        "pageTab": 144,
        "pageMobile": 165,
        "subpage": true,
        "pageIndex": 141,
        "pageIndexTab": 148,
        "pageIndexMobile": 172
    },
    {
        "id": 108,
        "title": "The Tale of Swamp Monster",
        "page": 277,
        "pageTab": 145,
        "pageMobile": 166,
        "subpage": true,
        "pageIndex": 142,
        "pageIndexTab": 149,
        "pageIndexMobile": 173
    },
    {
        "id": 109,
        "title": "The Tale of The Violinist",
        "page": 279,
        "pageTab": 146,
        "pageMobile": 167,
        "subpage": true,
        "pageIndex": 143,
        "pageIndexTab": 150,
        "pageIndexMobile": 174
    },
    {
        "id": 110,
        "title": "The Tale of Towns People",
        "page": 281,
        "pageTab": 147,
        "pageMobile": 168,
        "subpage": true,
        "pageIndex": 144,
        "pageIndexTab": 151,
        "pageIndexMobile": 175
    },
    {
        "id": 111,
        "title": "The Tale of Tsuku",
        "page": 283,
        "pageTab": 148,
        "pageMobile": 169,
        "subpage": true,
        "pageIndex": 145,
        "pageIndexTab": 152,
        "pageIndexMobile": 176
    },
    {
        "id": 112,
        "title": "Epilogue",
        "page": 286,
        "pageTab": 149,
        "pageMobile": 170,
        "subpage": false,
        "pageIndex": 146,
        "pageIndexTab": 153,
        "pageIndexMobile": 177
    }
]

export default ChapterPages